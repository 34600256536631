import { useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router';
import routes from 'config/routes';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useGlobalStore } from 'store/zustand/store';
import {
    MultiChoiceQuestionSubmissionInput,
    PredictiveQuestionSubmissionInput,
    Study,
    StudyFieldsFragment,
    StudySubmission,
    StudyWithAnswerFieldsFragment,
    SubmitStudyInput,
    SvgQuestionSubmissionInput
} from '__generated__/graphql';
import { SubmitButton } from './submit-button';
import { SUBMIT_STUDY } from 'graphql/mutations';
import { STUDY_SUBMISSION } from 'graphql/queries';
import { useStudyTimerStore } from 'store/zustand/study-timer';
import { DumbQuestionDrawer } from './dumb-question-drawer';
import { css } from '@emotion/react';

export type QuestionType = 'MultiChoiceQuestion' | 'PredictiveQuestion' | 'SvgQuestion';
type AnswerType = {
    questionType: QuestionType;
    answer: number | number[] | string | string[];
};

export type QuestionsForm = Record<QuestionType, Array<AnswerType>>;
export type StudySubmissionForDrawer =
    | (Omit<StudySubmission, 'study'> & { study: StudyFieldsFragment | StudyWithAnswerFieldsFragment })
    | null;

interface Props {
    currentStudy: Study | StudyFieldsFragment | StudyWithAnswerFieldsFragment;
    submission?: StudySubmissionForDrawer;
}

export default function QuestionsDrawer(props: Props) {
    const { currentStudy, submission } = props;
    const history = useHistory();
    const formMethods = useForm<QuestionsForm>({ reValidateMode: 'onChange' });
    const [setEndTime, startTime] = useStudyTimerStore((state) => [state.setEndTime, state.startTime]);
    const [submitStudyMutation] = useMutation(SUBMIT_STUDY);
    const [showCorrect, setShowCorrect] = useState<boolean>(false);

    const [setQuestionDrawerOpen, questionDrawerOpen] = useGlobalStore((state) => [
        state.setQuestionDrawerOpen,
        state.questionDrawerOpen
    ]);

    const studyData = submission?.study ?? currentStudy;

    const close = () => setQuestionDrawerOpen(false);
    const showReview = () => history.push(`${routes.AUTHED.CASE_REVIEW_PAGE.url}/${currentStudy.id}`);

    if (!studyData) {
        return <div>Loading...</div>;
    }

    const onSubmit = async (formData: QuestionsForm) => {
        setEndTime();

        const mcqs: MultiChoiceQuestionSubmissionInput[] = Object.entries(formData.MultiChoiceQuestion).map(
            ([q, v]) => ({
                questionId: Number(q.replace('q-', '')),
                answerId: Number(v.answer)
            })
        );

        const svgs: SvgQuestionSubmissionInput[] = Object.entries(formData.SvgQuestion).map(([q, v]) => ({
            questionId: Number(q.replace('q-', '')),
            selectedSegments: v.answer as string[]
        }));

        const preds: PredictiveQuestionSubmissionInput[] = Object.entries(formData.PredictiveQuestion).map(
            ([q, v]) => ({
                questionId: Number(q.replace('q-', '')),
                answerIds: v.answer === 'none-of-the-above' ? null : [Number(v.answer)]
            })
        );

        const submission: SubmitStudyInput = {
            studyId: studyData.id,
            questionSelections: [
                ...mcqs.map((s) => ({ multiChoiceSelection: s })),
                ...svgs.map((s) => ({ svgSelection: s })),
                ...preds.map((s) => ({
                    predictiveSelection: s
                }))
            ],
            startTime: startTime?.toISOString() ?? 'no-start',
            // just using `endTime` gives me a stale `null`,
            // this forces getting the latest value
            endTime: useStudyTimerStore.getState().endTime?.toISOString() ?? 'no-end'
        };

        await submitStudyMutation({
            variables: { input: submission },
            refetchQueries: [STUDY_SUBMISSION]
        });
        setShowCorrect(true);
    };

    return (
        <DumbQuestionDrawer
            submission={submission}
            showCorrect={showCorrect}
            currentStudy={currentStudy}
            open={questionDrawerOpen}
            onClose={() => setQuestionDrawerOpen(false)}
            onOpen={() => setQuestionDrawerOpen(true)}
            formMethods={formMethods}
            onSubmit={onSubmit}
            onGoToReview={showReview}
            drawerHeader={
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 1rem;
                    `}
                >
                    <SubmitButton onClick={close}>
                        Hide Case Questions <ChevronRightIcon />
                    </SubmitButton>
                </div>
            }
            sidebarProps={{
                colour: '#515151'
            }}
        />
    );
}
