import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { LogbookDataForAllSubmissionsQuery } from '__generated__/graphql';
import routes from 'config/routes';
import { Link } from 'react-router-dom';

const StyledTableHeader = styled(TableCell)`
    &.MuiTableCell-head {
        font-weight: bold;
    }
`;

interface Props {
    data: LogbookDataForAllSubmissionsQuery;
}

export default function LogbookTable(props: Props) {
    const { data } = props;

    return (
        <TableContainer component={Paper}>
            <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <StyledTableHeader align="center" rowSpan={2}>
                            Review
                        </StyledTableHeader>
                        <StyledTableHeader align="center" rowSpan={2}>
                            Exam ID
                        </StyledTableHeader>
                        <StyledTableHeader align="center" rowSpan={2}>
                            Date
                        </StyledTableHeader>
                        <StyledTableHeader align="center" colSpan={7}>
                            Case Type
                        </StyledTableHeader>
                        <StyledTableHeader align="center" rowSpan={2}>
                            Episode Number
                        </StyledTableHeader>
                        <StyledTableHeader align="center" rowSpan={2}>
                            Facility
                        </StyledTableHeader>
                        <StyledTableHeader align="center" rowSpan={2}>
                            Reporting Doctor
                        </StyledTableHeader>
                        <StyledTableHeader align="center" rowSpan={2}>
                            Name of Supervising Specialist
                        </StyledTableHeader>
                    </TableRow>
                    <TableRow>
                        <StyledTableHeader align="center">Live</StyledTableHeader>
                        <StyledTableHeader align="center">Library</StyledTableHeader>
                        <StyledTableHeader align="center">Case From CT Course?</StyledTableHeader>
                        <StyledTableHeader align="center">DLP</StyledTableHeader>
                        <StyledTableHeader align="center">Correlated</StyledTableHeader>
                        <StyledTableHeader align="center">Non-coronary cardiac findings</StyledTableHeader>
                        <StyledTableHeader align="center">Non-cardiac findings</StyledTableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.studySubmissions?.map((row) => {
                        const cpd = row.study.cpdData;
                        const {
                            live,
                            library,
                            fromCTCourse,
                            dlp,
                            correlated,
                            nonCoronaryCardiacFindings,
                            nonCardiacFindings,
                            episodeNumber,
                            facility,
                            reportingDoctor,
                            supervisingSpecialist
                        } = cpd;

                        return (
                            <TableRow key={row.id}>
                                <TableCell align="center" width="6rem">
                                    <Link to={`${routes.AUTHED.CASE_REVIEW_PAGE.url}/${row.study.id}`}>
                                        Click to review
                                    </Link>
                                </TableCell>
                                <TableCell align="center" component="th" scope="row">
                                    {row.id}
                                </TableCell>
                                <TableCell align="center">
                                    {new Date(Number(row.createdAt)).toLocaleDateString()}
                                </TableCell>
                                <TableCell align="center">{live ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="center">{library ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="center">{fromCTCourse ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="center">{dlp}</TableCell>
                                <TableCell align="center">{correlated ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="center">{nonCoronaryCardiacFindings ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="center">{nonCardiacFindings ? 'Yes' : 'No'}</TableCell>
                                <TableCell align="center">{episodeNumber}</TableCell>
                                <TableCell align="center">{facility}</TableCell>
                                <TableCell align="center">{reportingDoctor}</TableCell>
                                <TableCell align="center">{supervisingSpecialist}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
