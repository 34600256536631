import {
    MultiChoiceQuestionSubmission,
    SvgQuestionSubmission,
    PredictiveQuestionSubmission,
    MultiChoiceQuestion,
    PredictiveQuestion,
    SvgQuestion,
    Study,
    StudyFieldsFragment,
    StudyWithAnswerFieldsFragment
} from '__generated__/graphql';
import PredictiveTextQuestion from 'components/case/questions/PredictiveTextQuestion';
import RadioQuestion from 'components/case/questions/RadioQuestion';
import SVGQuestion from 'components/case/questions/SVGQuestion';
import { useMemo } from 'react';
import { QuestionType, QuestionsForm, StudySubmissionForDrawer } from '.';
import { QuestionContainer } from './question-container';
import { css } from '@emotion/react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Divider, Drawer, Loader } from '@mantine/core';
import { differenceInSeconds } from 'date-fns';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface Props {
    submission?: StudySubmissionForDrawer;
    showCorrect: boolean;
    currentStudy: Study | StudyFieldsFragment | StudyWithAnswerFieldsFragment;
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
    drawerHeader?: React.ReactNode;
    formMethods: ReturnType<typeof useForm<QuestionsForm>>;
    onSubmit: (formData: QuestionsForm) => void;
    onGoToReview: () => void;
    sidebarProps?: {
        colour?: string;
    };
}

export function DumbQuestionDrawer(props: Props) {
    const {
        submission,
        showCorrect,
        currentStudy,
        open,
        onClose,
        onOpen,
        drawerHeader,
        formMethods,
        onSubmit,
        onGoToReview,
        sidebarProps
    } = props;
    const questionsReadOnly = showCorrect || !!submission;
    const studyData = submission?.study ?? currentStudy;

    const sortedQuestions = useMemo(() => {
        return [...(studyData?.questions ?? [])]?.sort((a, b) => a.sequence - b.sequence);
    }, [studyData]);

    const mapQuestions = useMemo(() => {
        const getSelectionForQuestion = (questionId: number, questionType: QuestionType) => {
            const q = submission?.questionSelections?.find(
                (s) => s.question.id === questionId && s.question.__typename === questionType
            );

            console.log('found selection', { questionId, questionType, q });

            if (!q) {
                return undefined;
            }

            if (questionType === 'MultiChoiceQuestion') {
                return (q as unknown as MultiChoiceQuestionSubmission).answer.id;
            }

            if (questionType === 'SvgQuestion') {
                return (q as unknown as SvgQuestionSubmission).selectedSegments;
            }

            if (questionType === 'PredictiveQuestion') {
                return (q as unknown as PredictiveQuestionSubmission).answers?.map((a) => a.id)?.[0];
            }

            return undefined;
        };

        const mapMultiChoiceQuestion = (q: MultiChoiceQuestion) => (
            <QuestionContainer>
                <RadioQuestion
                    question={q}
                    showCorrect={questionsReadOnly}
                    overrideSelection={getSelectionForQuestion(q.id, 'MultiChoiceQuestion') as number}
                />
            </QuestionContainer>
        );

        const mapPredictiveTextQuestion = (q: PredictiveQuestion) => (
            <QuestionContainer>
                <PredictiveTextQuestion
                    question={q}
                    showCorrect={questionsReadOnly}
                    overrideSelection={getSelectionForQuestion(q.id, 'PredictiveQuestion') as number}
                />
            </QuestionContainer>
        );

        const mapSvgQuestion = (q: SvgQuestion) => (
            <QuestionContainer>
                <SVGQuestion
                    question={q}
                    showCorrect={questionsReadOnly}
                    overrideSelection={getSelectionForQuestion(q.id, 'SvgQuestion') as string[]}
                />
            </QuestionContainer>
        );

        const RenderMap = {
            MultiChoiceQuestion: mapMultiChoiceQuestion,
            PredictiveQuestion: mapPredictiveTextQuestion,
            SvgQuestion: mapSvgQuestion
        };

        // @ts-expect-error no __typename on generated type
        return sortedQuestions.map((q) => RenderMap[q.__typename as keyof typeof Render](q));
    }, [questionsReadOnly, sortedQuestions, submission?.questionSelections]);

    const handleClose = () => {
        onClose?.();
    };

    return (
        <div
            css={css`
                display: flex;
                background-color: #f0f0f0;
            `}
        >
            <div
                css={css`
                    height: 100%;
                    width: 3rem;
                    background-color: ${sidebarProps?.colour ?? '#f0f0f0'};
                    border: 1px solid black;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 10;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    gap: 2rem;

                    cursor: pointer;
                    writing-mode: vertical-lr;
                    text-orientation: upright;

                    &:hover {
                        background-color: #515151;
                    }
                `}
                onClick={onOpen}
            >
                <ChevronLeftIcon />
            </div>
            {/* <Divider /> */}
            <FormProvider {...formMethods}>
                <Drawer.Root
                    size="xl"
                    css={css`
                        z-index: 10;
                    `}
                    variant="persistent"
                    position="right"
                    opened={open}
                    onClose={handleClose}
                >
                    {formMethods.formState.isSubmitting && (
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: '#1d1d1d76',
                                zIndex: 999999,
                                backdropFilter: 'blur(2px)'
                            }}
                        >
                            <Loader />
                        </div>
                    )}
                    <Drawer.Overlay />
                    <Drawer.Content data-tour="questions-container">
                        <Drawer.Header
                            css={css`
                                align-items: flex-start;
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    gap: 1rem;
                                `}
                            >
                                {drawerHeader}
                                <h4
                                    css={css`
                                        font-size: 1.2rem;
                                        margin: 0;
                                    `}
                                >
                                    {submission ? (
                                        <>Review your answers, the click the "Go to review" button to continue.</>
                                    ) : (
                                        <>Answer the quesions below, then click the submit button to continue.</>
                                    )}
                                </h4>
                            </div>
                            <Drawer.CloseButton />
                        </Drawer.Header>
                        <form onSubmit={formMethods.handleSubmit(onSubmit, console.log)}>
                            <Drawer.Body
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    gap: 1rem;
                                    padding-top: 0;
                                `}
                            >
                                {mapQuestions}
                                <div
                                    css={css`
                                        display: flex;
                                        justify-content: center;
                                        margin: 1.5rem 0;
                                    `}
                                >
                                    {questionsReadOnly ? (
                                        <Button onClick={onGoToReview}>Go to review</Button>
                                    ) : (
                                        <Button type="submit" data-tour="submit-answers">
                                            Submit answers
                                        </Button>
                                    )}
                                </div>
                                <div
                                    css={css`
                                        font-size: 0.9rem;
                                        margin-bottom: 1rem;
                                        display: flex;
                                        flex-direction: column;
                                    `}
                                >
                                    <div
                                        css={css`
                                            font-style: italic;
                                        `}
                                    >
                                        {currentStudy.displayName}
                                    </div>
                                    {submission && (
                                        <>
                                            {' '}
                                            Time spent:{' '}
                                            {differenceInSeconds(
                                                new Date(Number(submission.endTime)),
                                                new Date(Number(submission.startTime))
                                            )}{' '}
                                            seconds
                                        </>
                                    )}
                                </div>
                            </Drawer.Body>
                        </form>
                    </Drawer.Content>
                </Drawer.Root>
            </FormProvider>
        </div>
    );
}
