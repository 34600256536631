import { CpdData, LogbookDataForAllSubmissionsQuery } from '__generated__/graphql';
import * as ExcelJS from 'exceljs';

export const exportLogbook = async (data: LogbookDataForAllSubmissionsQuery) => {
    const wb = new ExcelJS.Workbook();
    wb.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 1,
            visibility: 'visible'
        }
    ];

    const ws = wb.addWorksheet('Sheet 1');

    ws.addRow([
        'Exam ID',
        'Date',
        'Live',
        'Library',
        'Live Cases only: Case from CT course?',
        'DLP',
        'Correlated',
        'Non-coronary cardiac findings',
        'Non-cardiac findings',
        'Unique Episode Number',
        'Facility',
        'Reporting Doctor',
        'Name of Supervising Specialist'
    ]);

    if (!data.studySubmissions?.length) {
        return;
    }

    ws.addRows(
        data.studySubmissions.map((s) => {
            const { id, createdAt } = s;
            const cpd: Partial<CpdData> = { ...s.study.cpdData };
            delete cpd.__typename;
            delete cpd.id;

            return [id, new Date(Number(createdAt)).toLocaleDateString(), ...Object.values(cpd)];
        })
    );

    const buf = await wb.xlsx.writeBuffer();
    const a = document.createElement('a');
    const d = new Blob([buf], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(d);
    a.href = url;
    a.download = 'TheCardiacCTCourse-Logbook.xlsx';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
        document.body.removeChild(a);
    }, 0);
};
