import React, { useState } from 'react';
import { LineChart } from './components/LineChart';
import { BarChart } from './components/BarChart';
import {
    CategoryScale,
    Chart,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    RadialLinearScale,
    Filler,
    BarElement,
    ArcElement,
    DoughnutController,
    LineController
} from 'chart.js';
import { css } from '@emotion/react';
import { useQuery } from '@apollo/client';
import { PROGRESS_STATS } from 'graphql/queries';
import { CaseCompletions } from './components/CaseCompletions';
import { Button, Divider, LoadingOverlay, Select, Text } from '@mantine/core';
import { ctsReported, jurisdictions, specialtyOptions, yearsReporting } from '../UserProfilePage/data';
import { HorizontalBarChart } from './components/HorizontalBarChart';
import { PerLesionBias } from './components/per-lesion-bias';
import { mq } from 'config/breakpoints';

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler,
    BarElement,
    ArcElement,
    DoughnutController,
    LineController
);

interface SectionProps {
    children: React.ReactNode;
    heading: string;
    content: React.ReactNode;
}

const Section = (props: SectionProps) => {
    const { children, heading, content } = props;
    return (
        <div
            css={css`
                display: flex;
                gap: 2rem;
                height: 100%;
                border-bottom: 1px solid #bebebe;
                padding: 0 2rem;
                align-items: center;

                flex-wrap: wrap;
            `}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    flex: 2 2 14rem;
                `}
            >
                <h2>{heading}</h2>
                <p
                    css={css`
                        font-size: 0.9rem;
                    `}
                >
                    {content}
                </p>
            </div>
            <div
                css={css`
                    flex: 5 5 22rem;
                    max-height: 100%;
                    display: flex;
                    justify-content: center;
                `}
            >
                {children}
            </div>
        </div>
    );
};

interface Filter {
    specialty: string | null;
    yearsReporting: string | null;
    numCtsReported: string | null;
    jurisdiction: string | null;
}

const defaultFilter = {
    specialty: null,
    yearsReporting: null,
    numCtsReported: null,
    jurisdiction: null
};

const UserProgressPage: React.FunctionComponent = () => {
    const { data, loading } = useQuery(PROGRESS_STATS, { fetchPolicy: 'network-only' });
    const [filter, setFilter] = useState<Filter>(defaultFilter);

    const groups = () => {
        const d = data?.me?.stats.accuracy ?? [];
        return d.reduce(
            (acc, cur) => {
                acc[cur.numCorrectAnswers]++;
                return acc;
            },
            [0, 0, 0, 0, 0]
        );
    };

    const percentiles = data?.me?.stats.ranking.map((r) => r.percentile) ?? [];
    const latestPercentile = percentiles.length ? percentiles[percentiles.length - 1] : 0;
    const bias = data?.me?.stats.bias;
    const progress = data?.me?.stats?.progress;

    const setFilterItem = (item: keyof typeof defaultFilter) => (value: string | null) => {
        setFilter((f) => ({ ...f, [item]: value }));
    };

    return (
        <div
            css={css`
                min-height: calc(100vh - 10rem);
                position: relative;
                margin: 0 3rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
            `}
        >
            <LoadingOverlay visible={loading} />
            <h2>Review your progress</h2>
            {progress?.completedStudies === 0 && (
                <Text size="lg">Your progress stats will appear once you start completing studies.</Text>
            )}
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                `}
            >
                <div>
                    Assess your standing amongst the entire cohort of Cardiac CT Course users, or refine the comparison
                    pool based on participant attributes.
                </div>
                <div
                    css={css`
                        display: flex;
                        gap: 1rem;
                    `}
                >
                    <Select
                        label="Specialty"
                        data={specialtyOptions}
                        value={filter.specialty}
                        onChange={setFilterItem('specialty')}
                    />
                    <Select
                        label="Years reporting cardiac CT"
                        data={yearsReporting}
                        value={filter.yearsReporting}
                        onChange={setFilterItem('yearsReporting')}
                    />
                    <Select
                        label="Number of cardiac studies reported"
                        data={ctsReported}
                        value={filter.numCtsReported}
                        onChange={setFilterItem('numCtsReported')}
                    />
                    <Select
                        label="Jurisdiction"
                        data={jurisdictions}
                        value={filter.jurisdiction}
                        onChange={setFilterItem('jurisdiction')}
                    />
                </div>
                <div>
                    <Button onClick={() => setFilter(defaultFilter)}>Clear filters</Button>
                </div>
            </div>
            <Divider />
            <div
                css={css`
                    max-width: 100%;
                    width: 100%;
                    display: grid;

                    grid-template-columns: 1fr;

                    ${mq('xl')} {
                        grid-template-columns: 1fr 1fr;
                    }

                    grid-auto-rows: minmax(25rem, 1fr);
                    gap: 3rem;
                    height: 100%;
                `}
            >
                {/* <Section
                    heading="Confusion Matrix"
                    content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed suscipit leo quis malesuada
                        laoreet. Donec dapibus, tortor a rutrum egestas, justo lorem consequat velit, quis pretium est
                        est at mauris."
                >
                    <ConfusionMatrix />
                </Section> */}
                <Section
                    heading="Course Progress"
                    content="Your overall number of studies completed within The Cardiac CT Course program."
                >
                    <CaseCompletions
                        totalCases={progress?.totalStudies ?? 0}
                        completedCases={progress?.completedStudies ?? 0}
                    />
                </Section>
                <Section
                    heading="Accuracy"
                    content={
                        <div>
                            This report shows how many questions are answered correctly for each study submitted.
                            <br />
                            <br />
                            You usually get {groups().indexOf(Math.max(...groups()))} out of 4 questions per case
                            correct.
                        </div>
                    }
                >
                    <BarChart data={groups()} />
                </Section>
                <Section
                    heading="Ranking"
                    content={
                        <div>
                            Check your ranking against users of The Cardiac CT Course. <br />
                            <br />
                            You are in the top {(100 - latestPercentile).toFixed(0)}% of the Cardiact CT cohort.
                            <br />
                            <br />
                            As you continue completing studies and as time goes on, your rank will automatically update,
                            showing how your overall position in the Cardiac CT Course cohort changes.
                        </div>
                    }
                >
                    <LineChart data={percentiles} />{' '}
                </Section>
                {!!bias && (
                    <div
                        css={css`
                            /* grid-column: 1 /3; */
                        `}
                    >
                        <Section
                            heading="Reporting Bias"
                            content={
                                <div>
                                    This report looks at your ability to correctly identify regions of haemodynamically
                                    significant stenoses.
                                    <br />
                                    <br />
                                    The dataset shows on a per coronary segment basis, whether significant stenoses are
                                    correctly identified.
                                    <br />
                                    <br />
                                    The pink dataset represents you, the grey dataset represents an average of the
                                    Cardiac CT Course cohort.
                                </div>
                            }
                        >
                            <div
                                css={css`
                                    display: flex;
                                    /* flex-direction: column; */

                                    width: 100%;
                                    gap: 1rem;
                                `}
                            >
                                <HorizontalBarChart
                                    // title="True Positive"
                                    user={bias.userBias}
                                    cohort={bias.cohortBias}
                                />
                                {/* <HorizontalBarChart
             title="False Positive"
             user={bias?.userBias.falsePositive ?? 0}
             cohort={bias?.cohortBias.falsePositive ?? 0}
         />
         <HorizontalBarChart
             title="True Negaitve"
             user={bias?.userBias.trueNegative ?? 0}
             cohort={bias?.cohortBias.trueNegative ?? 0}
         />
         <HorizontalBarChart
             title="False Negative"
             user={bias?.userBias.falseNegative ?? 0}
             cohort={bias?.cohortBias.falseNegative ?? 0}
         /> */}
                            </div>
                        </Section>
                    </div>
                )}
                <div
                    css={css`
                        ${mq('xl')} {
                            grid-column: 1 / 3;
                        }
                    `}
                >
                    {' '}
                    <Section
                        heading="Per-Lesion Reporting Bias"
                        content={
                            <div>
                                This report looks at your ability to correctly identify regions of haemodynamically
                                significant stenoses.
                                <br />
                                <br />
                                The dataset shows on a per coronary segment basis, whether significant stenoses are
                                correctly identified.
                            </div>
                        }
                    >
                        <PerLesionBias user={bias?.userLesionBias ?? []} cohort={[]} />
                    </Section>
                </div>
            </div>
        </div>
    );
};

export default UserProgressPage;
