import * as React from "react";
const SvgRcaLcaVer3 = (props) => /* @__PURE__ */ React.createElement("svg", { width: "212.396mm", height: "94.387993mm", viewBox: "0 0 212.39599 94.387993", id: "rca-and-lca", "inkscape:version": "1.3.2 (091e20e, 2023-11-25)", "sodipodi:docname": "RCA-LCA-ver3.svg", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#", "xmlns:cc": "http://creativecommons.org/ns#", "xmlns:dc": "http://purl.org/dc/elements/1.1/", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs1254" }), /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "base", pagecolor: "#ffffff", bordercolor: "#666666", borderopacity: 1, "inkscape:pageopacity": 0, "inkscape:pageshadow": 2, "inkscape:zoom": 1.979899, "inkscape:cx": 281.32748, "inkscape:cy": 198.74751, "inkscape:document-units": "mm", "inkscape:current-layer": "layer1", showgrid: "false", "inkscape:window-width": 3008, "inkscape:window-height": 1590, "inkscape:window-x": 2560, "inkscape:window-y": 25, "inkscape:window-maximized": 1, "inkscape:showpageshadow": 2, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#d1d1d1" }), /* @__PURE__ */ React.createElement("metadata", { id: "metadata1257" }, /* @__PURE__ */ React.createElement("rdf:RDF", null, /* @__PURE__ */ React.createElement("cc:Work", { "rdf:about": "" }, /* @__PURE__ */ React.createElement("dc:format", null, "image/svg+xml"), /* @__PURE__ */ React.createElement("dc:type", { "rdf:resource": "http://purl.org/dc/dcmitype/StillImage" })))), /* @__PURE__ */ React.createElement("g", { "inkscape:label": "Layer 1", "inkscape:groupmode": "layer", id: "layer1", transform: "translate(-6.8233215,-4.6313691)" }, /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "OM2", d: "m 124.13228,76.334005 c 5.60339,1.90591 9.56604,7.1016 10.65821,9.11524 1.09217,2.01365 5.64482,5.22388 5.64482,5.22388 7.39495,6.2746 7.39495,8.34624 7.39495,8.34624 0.22373,-2.74451 -3.48036,-7.00217 -6.94748,-9.41024 -3.4671,-2.4081 -4.87251,-5.43436 -4.87251,-5.43436 -2.57713,-5.82712 -10.25217,-9.52461 -10.25217,-9.52461 z", id: "path312" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.12516
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "D2", d: "m 180.3337,48.283645 c 7.62929,3.012226 13.68971,8.100784 13.96854,8.452548 0.27884,0.351762 2.64056,2.961263 2.64056,2.961263 6.15577,5.054663 5.64688,5.735852 5.64688,5.735852 0,0 -0.12198,-2.134138 -2.60956,-4.542056 -2.48756,-2.407912 -3.50832,-3.680214 -3.50832,-3.680214 -8.31039,-9.218339 -17.41856,-10.268597 -17.41856,-10.268597 z", id: "path314", "sodipodi:nodetypes": "csccsccc" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "LAD_Mid", d: "m 164.72662,41.296655 a 1.4352373,1.4352373 0 0 1 1.14188,-0.0314 28.306988,28.306988 0 0 1 11.70895,8.37608 l 1.64241,-1.92082 c -2.62851,-3.4058 -5.77576,-5.78238 -9.59753,-8.45232 -1.46507,-1.02258 -3.75548,-2.00369 -6.54308,-2.93015 1.1485,1.74516 1.891,3.41243 1.64737,4.95869 z", id: "path316" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "LAD_Dist", d: "m 185.47623,61.489415 c -1.65732,-6.13209 -3.62786,-10.27538 -6.05086,-13.49886 l -1.63743,1.91089 c 3.04283,3.72564 4.21123,6.99388 4.21123,6.99388 10.53889,29.29971 -0.66789,36.37646 -0.66789,36.37646 8.29157,-4.85926 7.61869,-18.89343 4.14495,-31.78237 z", id: "path318" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, d: "m 163.91407,42.056875 c 0.12406,-0.1878 0.27099,-0.36029 0.43799,-0.51325 l 0.0464,-0.29997 c 0.20054,-1.27447 -0.30494,-2.7727 -1.59599,-4.71508 l -0.23203,-0.34802 c -3.36436,-1.08721 -12.32212,-2.618823 -16.48861,-3.508803 l -0.76186,4.796274 c 1.06849,0.232833 9.98154,-0.07145 11.3587,0.301889 2.77269,0.75573 4.2013,1.596 4.81783,3.6693 z", id: "path1", "data-name": "LAD_Prox", "sodipodi:nodetypes": "cccccccccc" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, d: "m 161.56619,41.915995 c 0.003,2.45572 -2.18074,7.48787 -3.24843,9.46708 -1.18995,2.2059 -1.9722,6.91931 -1.93077,9.38374 -0.002,1.5845 -0.28632,3.15597 -0.84025,4.6405 1.30264,-0.25193 1.68052,-5.25204 2.26886,-8.69761 0.58834,-3.44556 2.39483,-6.26135 2.39483,-6.26135 0,0 2.67533,-6.07138 3.51558,-8.00381 z", id: "path320", "data-name": "D1" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "OM1", d: "m 132.98401,62.468875 c 0,0 5.21061,3.16548 7.45793,8.31974 2.24732,5.15426 12.15477,7.28225 12.15477,7.28225 7.45792,3.36104 9.24452,9.18817 9.24452,9.18817 -1.90592,-10.08477 -12.80113,-11.68078 -16.40745,-13.69773 -3.60632,-2.01695 -6.13208,-8.78378 -6.13208,-8.78378 0,0 -0.7143,-1.38552 -4.57917,-4.2013 -1.28939,0.002 -1.77663,0.87507 -1.73852,1.89265 z", id: "path322" }), /* @__PURE__ */ React.createElement("path", { "sodipodi:nodetypes": "cccccccscccccc", style: {
  strokeWidth: 0.165732
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "LCX", d: "m 133.20609,60.814885 c 0.30008,-0.3028 0.69494,-0.4935 1.11869,-0.54029 -3.29309,-2.72462 -0.91153,-5.5371 -0.91153,-5.5371 0,0 5.80062,-10.61512 8.37443,-14.36895 0.45332,-1.247938 2.49495,-2.343059 3.04513,-2.526024 l -7.29614,-0.688926 c -0.22254,1.0874 -0.93142,2.12278 -0.93142,2.12278 0,0 -4.9156,9.49477 -5.92326,12.26416 -1.00764,2.76936 -3.68421,8.31806 -3.68421,8.31806 -9.01913,15.54731 -11.63107,25.25256 -11.63107,25.25256 0.0846,0.41931 1.98878,0.46239 1.98878,0.46239 -0.28007,-3.02625 10.14114,-20.05355 11.65262,-22.4086 1.17503,-1.82305 2.9235,-1.23966 3.64609,-0.885 -0.008,-0.54055 0.18945,-1.06398 0.55189,-1.46506 z", id: "path324" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, d: "m 121.37697,34.584196 c 1.73014,-1.594322 14.1796,1.437841 16.27405,2.10511 l 7.29709,0.752371 0.7196,-4.840295 c -9.32573,-1.98986 -19.68653,-3.72747 -24.22227,-4.28878 z", id: "path2", "data-name": "LMA", "sodipodi:nodetypes": "cccccc" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, d: "m 121.10632,23.583104 c -5.49401,1.94072 -10.94026,2.053551 -15.6371,0.258671 l -0.22374,22.1285 c 4.70679,1.891 9.83286,2.04348 15.41306,0.28009 l 0.21048,-6.59611 z", id: "path326", className: "no-click", "sodipodi:nodetypes": "cccccc" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.136834
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "D3", d: "m 188.78856,83.58905 c 0,0 11.15059,5.674136 14.9053,13.617021 0,0 -1.34988,-7.658379 -14.84996,-15.311664 z", id: "path328" }), /* @__PURE__ */ React.createElement("path", { style: {
  fillOpacity: 1,
  strokeWidth: 0.165732
}, d: "m 28.954285,35.158186 c -2.022226,0.362812 -5.658968,1.172333 -7.533842,1.703193 -0.907265,0.378185 -0.872047,0.320239 -1.542085,0.632176 -1.454279,0.71147 -2.222811,1.519625 -3.050823,2.7158 -1.613996,2.430694 -4.930488,8.681212 -5.73263,13.52721 l 3.710834,0.445729 c 0.11767,-0.959587 2.96829,-7.89713 3.833841,-9.320148 0.865551,-1.423018 1.716268,-2.546078 3.553273,-3.553521 2.443698,-1.451367 6.754598,-1.221184 6.754598,-1.221184 z", id: "path3", "data-name": "RCA_Prox", "sodipodi:nodetypes": "ccccccsccc" }), /* @__PURE__ */ React.createElement("path", { style: {
  fillOpacity: 1,
  strokeWidth: 0.165732
}, d: "m 30.407515,21.32111 -0.925361,-0.31495 0.02039,23.026032 c -0.02014,0.753768 -0.01942,1.714288 -0.01942,1.714288 4.870858,2.101479 10.130936,1.809843 15.658091,-0.112646 -0.08121,-0.707675 0.08098,-6.536306 0.25831,-12.104894 0.02251,-0.710787 0.04594,-1.988041 0.06894,-3.078339 l 0.02234,-0.01263 c 0.15413,-4.589113 0.298433,-8.430958 0.298433,-8.430958 -8.864855,3.291295 -14.893524,-0.727748 -15.38172,-0.685903 z", id: "path330", className: "no-click", "sodipodi:nodetypes": "cccccccccc" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "RCA_Mid", d: "m 14.805642,70.087575 c -1.035824,-3.024605 1.491586,-6.067441 1.491586,-6.067441 l -1.049082,-1.509816 -1.246303,2.227435 c -0.381183,-2.174401 0.63641,-9.380419 0.757394,-10.225651 l -3.720679,-0.447476 a 87.774871,87.774871 0 0 0 -0.406043,9.678736 c 0,0 -0.497195,8.724122 4.474759,13.101098 l 3.314635,-1.715324 c -2.505864,-1.231387 -2.99643,-3.236741 -3.616267,-5.041561 z", id: "path334" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "RCA_Dist", d: "m 47.101796,71.292445 c 0,0 -9.746687,4.594086 -20.167902,4.985213 -3.881438,0.147501 -6.407191,-0.256885 -8.105942,-0.974503 l -3.430648,1.771673 c 1.325855,1.075599 3.00306,1.856196 5.150944,2.116395 10.146101,1.234701 27.267852,-5.881822 27.267852,-5.881822 0.238654,-1.067312 -0.03149,-1.713666 -0.714304,-2.016956 z", id: "path336" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "PDA", d: "m 53.064826,71.292445 a 3.8781239,3.8781239 0 0 0 1.264533,0.924784 c 3.382586,1.721953 7.282255,4.36869 9.663821,7.534167 2.381566,3.165477 9.705253,10.23891 12.562469,11.120603 0,0 -6.0923,-1.595997 -13.528686,-10.378125 -6.629271,-7.835799 -13.540287,-7.408211 -14.949007,-7.225906 0.987761,-1.156808 2.447859,-1.953977 4.98687,-1.975523 z", id: "path338" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, d: "m 57.167365,65.92109 c -3.08107,-0.03319 -5.88613,2.793104 -6.65801,3.636952 -0.548721,0.423835 -0.741915,0.508784 -1.178634,0.72872 -0.548904,0.276427 -1.099879,0.548376 -1.653109,0.816035 0.167157,0.147501 0.304798,0.328464 0.402347,0.528918 0.145264,0.298491 0.200055,0.640199 0.155374,0.969142 0.232124,-0.24229 0.493599,-0.456537 0.776868,-0.636384 0.251829,-0.159888 0.520729,-0.292578 0.800816,-0.395232 0.965951,-0.401669 2.001791,-0.608373 3.047923,-0.608223 -0.5436,-1.168409 1.191512,-2.055138 1.191512,-2.055138 0,0 4.37058,-4.060338 7.199621,0.672962 2.829042,4.7333 8.962792,7.002168 8.962792,7.002168 -4.148266,-1.842938 -8.604778,-8.564968 -8.604778,-8.564968 -1.527848,-1.513338 -3.042239,-2.079867 -4.442722,-2.094952 z", id: "path342", "inkscape:connector-curvature": 0, "sodipodi:nodetypes": "ccccccccccsccc", "data-name": "PLB" }), /* @__PURE__ */ React.createElement("path", { style: {
  strokeWidth: 0.165732
}, "inkscape:connector-curvature": 0, className: "cls-1", "data-name": "RCA_Rm", d: "m 10.968951,79.14979 c 1.344085,9.368818 9.789777,7.268996 9.789777,7.268996 -8.866651,-1.009307 -8.278303,-7.941867 -8.278303,-7.941867 -0.447476,-0.434218 -1.428608,-0.177333 -1.511474,0.672871 z", id: "path344" }), /* @__PURE__ */ React.createElement("text", { y: 11.120066, x: 16.57456, style: {
  fontWeight: 700,
  fontSize: "8.46667px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.309283
}, className: "cls-2", id: "text348" }, "Right"), /* @__PURE__ */ React.createElement("text", { style: {
  fontWeight: 700,
  fontSize: "4.23333px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.165732
}, className: "cls-4", id: "text350", x: 32.943325, y: 21.566286 }, "Aorta"), /* @__PURE__ */ React.createElement("text", { y: 89.61306, x: 12.427521, style: {
  fontWeight: 700,
  fontSize: "4.23333px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.165732
}, className: "cls-4", id: "text354" }, "Rm"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(41.708886)", y: 23.165569, x: 97.381119, style: {
  fontWeight: 700,
  fontSize: "4.23333px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.165732
}, className: "cls-4", id: "text356" }, "PDA"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(-70.911099)", y: 27.506275, x: -42.296551, style: {
  fontWeight: 700,
  fontSize: "4.23333px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.166713
}, className: "cls-4", id: "text364" }, "Prox"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(-96.702817)", y: 2.6387215, x: -68.810791, style: {
  fontWeight: 700,
  fontSize: "4.23333px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.131006
}, className: "cls-4", id: "text366" }, "Mid"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(-13.542511)", y: 86.101463, x: 6.8644996, style: {
  fontWeight: 700,
  fontSize: "3.16083px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.131701
}, className: "cls-4", id: "text368" }, "Dist"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(51.20842)", y: -6.1012902, x: 90.388039, style: {
  fontWeight: 700,
  fontSize: "4.23333px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.165732
}, className: "cls-4", id: "text370" }, "PLB"), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.16573179,0,0,0.16573179,-7.5001998,9.2457779)", id: "g396" }, /* @__PURE__ */ React.createElement("text", { style: {
  fontWeight: 700,
  fontSize: "25.5433px",
  fontFamily: "Arial, sans-serif"
}, className: "cls-4", transform: "translate(696.87,82.62)", id: "text372" }, "Aorta"), /* @__PURE__ */ React.createElement("text", { y: -203.30403, x: 973.16608, style: {
  fontWeight: 700,
  fontSize: "25.5433px",
  fontFamily: "Arial, sans-serif"
}, className: "cls-4", transform: "rotate(39.498718)", id: "text374" }, "OM2"), /* @__PURE__ */ React.createElement("text", { y: -185.96143, x: 965.58118, style: {
  fontWeight: 700,
  fontSize: "25.5433px",
  fontFamily: "Arial, sans-serif"
}, className: "cls-4", transform: "rotate(32.897842)", id: "text376" }, "OM1"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(81.648195)", y: -1111.2213, x: 489.40558, style: {
  fontWeight: 700,
  fontSize: "25.5433px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.619622
}, className: "cls-4", id: "text378" }, "LAD Dist"), /* @__PURE__ */ React.createElement("text", { transform: "matrix(0.8245745,0.55425581,-0.53621397,0.85231872,0,0)", y: -298.69128, x: 1281.2253, style: {
  fontWeight: 700,
  fontSize: "25.5433px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.825635
}, className: "cls-4", id: "text380" }, "D3"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(31.201877)", y: -405.44104, x: 1150.6912, style: {
  fontWeight: 700,
  fontSize: "25.5433px",
  fontFamily: "Arial, sans-serif"
}, className: "cls-4", id: "text382" }, "D2"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(-64.677608)", y: 1010.702, x: 178.21857, style: {
  fontWeight: 700,
  fontSize: "25.5433px",
  fontFamily: "Arial, sans-serif"
}, className: "cls-4", id: "text384" }, "D1"), /* @__PURE__ */ React.createElement("text", { y: 850.48712, x: 171.00385, style: {
  fontWeight: 700,
  fontSize: 24,
  fontFamily: "Arial, sans-serif"
}, className: "cls-4", transform: "rotate(-62.15373)", id: "text386" }, "LCX"), /* @__PURE__ */ React.createElement("text", { y: -26.951527, x: 863.87677, style: {
  fontWeight: 700,
  fontSize: "25.5433px",
  fontFamily: "Arial, sans-serif"
}, className: "cls-4", id: "text388", transform: "rotate(9.9680153)" }, "LMA"), /* @__PURE__ */ React.createElement("text", { y: 9.3498135, x: 760.13953, style: {
  fontWeight: 700,
  fontSize: "51.0866px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 2.3367
}, className: "cls-2", id: "text390" }, "Left"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(14.221803)", y: -97.904404, x: 957.4118, id: "text392", className: "cls-4", style: {
  fontWeight: 700,
  fontSize: "19.1575px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.53431
} }, "LAD Prox"), /* @__PURE__ */ React.createElement("text", { transform: "rotate(38.518156)", style: {
  fontWeight: 700,
  fontSize: "19.1575px",
  fontFamily: "Arial, sans-serif",
  strokeWidth: 0.612818
}, className: "cls-4", id: "text394", x: 941.66217, y: -525.59546 }, "LAD Mid"))));
export default SvgRcaLcaVer3;
