import { gql } from '__generated__';

export const GET_SELF = gql(`
    query me {
        me {
            id
            email
            firstName
            lastName
            organisation
            accreditationJurisdiction
            yearsReportingCt
            numCtsReported
            specialty
            productSubscriptions {
                startDate
                endDate
                product
            }
            cme {
                register
                college
                collegeId
                careerStage
                specialty
            }
        }
    }
`);

export const STUDY_FRAGMENT = gql(`
    fragment StudyFields on Study {
        id
        sequence
        displayName
        dicomStudyUid
        questions {
            ... on MultiChoiceQuestion {
                __typename
                id
                sequence
                text
                possibleAnswers {
                    id
                    text
                    sequence
                }
            }
            ... on PredictiveQuestion {
                __typename
                id
                sequence
                text
                possibleAnswers {
                    id
                    text
                }
            }
            ... on SvgQuestion {
                __typename
                id
                sequence
                text
                svgAssetUrl
            }
        }
    }
`);

export const STUDY_WITH_ANSWERS_FRAGMENT = gql(`
    fragment StudyWithAnswerFields on Study {
        id
        sequence
        displayName
        dicomStudyUid
        questions {
            ... on MultiChoiceQuestion {
                __typename
                id
                sequence
                text
                possibleAnswers {
                    id
                    text
                    sequence
                    isCorrect
                }
            }
            ... on PredictiveQuestion {
                __typename
                id
                sequence
                text
                possibleAnswers {
                    id
                    text
                }
                correctAnswers {
                    id
                    text
                }
            }
            ... on SvgQuestion {
                __typename
                id
                sequence
                text
                svgAssetUrl
                correctSegments
            }
        }
    }
`);

export const STUDY_BY_SEQUENCE = gql(`
    query StudyBySequence($sequence: Int!) {
        studyBySequence(sequence: $sequence) {
            ...StudyFields
        }
    }
`);

export const STUDY_BY_ID = gql(`
    query StudyById($id: Int!) {
        study(id: $id) {
            ...StudyFields
        }
    }
`);

export const NEXT_STUDY = gql(`
    query NextStudy {
        nextStudy {
            ...StudyFields
        }
    }
`);

export const STUDY_WITH_ANSWERS_BY_ID = gql(`
    query StudyWithAnswersById($id: Int!) {
        study(id: $id) {
            ...StudyWithAnswerFields
        }
    }
`);

export const STUDY_BY_SEQUENCE_WITH_ANSWERS = gql(`
    query StudyBySequenceWithAnswers($sequence: Int!) {
        studyBySequence(sequence: $sequence) {
            ...StudyWithAnswerFields
        }
    }
`);

export const STUDY_BY_ID_WITH_ANSWERS = gql(`
    query StudyByIdWithAnswers($id: Int!) {
        study(id: $id) {
            ...StudyWithAnswerFields
        }
    }
`);

export const STUDY_REVIEW_BY_STUDY_ID = gql(`
    query StudyReviewByStudyId($id: Int!) {
        study(id: $id) {
            id
            sequence
            displayName
            review {
                id
                angioUid
                ctcaFindings
                nonCardiacFindings
                areasOfInterest {
                    id
                    title
                    imageAssetPath
                    dicomUid
                    segmentName
                }
            }
        }
    }
`);

export const STUDY_SUBMISSION = gql(`
    query StudySubmission($studyId: Int!) {
        studySubmission(studyId: $studyId) {
            id
            createdAt
            startTime
            endTime
            study {
                ...StudyWithAnswerFields
            }
            questionSelections {
                ... on MultiChoiceQuestionSubmission {
                    __typename
                    question {
                        id
                        text
                        sequence
                    }
                    answer {
                        id
                        text
                        sequence
                        isCorrect
                    }
                }
                ... on SvgQuestionSubmission {
                    __typename
                    question {
                        id
                        text
                        sequence
                        correctSegments
                    }
                    selectedSegments
                }
                ... on PredictiveQuestionSubmission {
                    __typename
                    question {
                        id
                        sequence
                        text
                        possibleAnswers {
                            id
                            text
                        }
                        correctAnswers {
                            id
                            text
                        }
                    }
                    answers {
                        id
                        text
                    }
                }
            }
        }
    }
`);

export const LOGBOOK_DATA_ALL_SUBMISSIONS = gql(`
    query LogbookDataForAllSubmissions {
        studySubmissions {
            id
            createdAt
            study {
                id
                cpdData {
                    id
                    live
                    library
                    fromCTCourse
                    dlp
                    correlated
                    nonCoronaryCardiacFindings
                    nonCardiacFindings
                    episodeNumber
                    facility
                    reportingDoctor
                    supervisingSpecialist
                }
            }
        }
    }
`);

export const PROGRESS_STATS = gql(`
    query ProgressStats {
        me {
            stats {
                accuracy {
                    numQuestions
                    numCorrectAnswers
                }
                ranking {
                    date
                    percentile
                }
                bias {
                    userBias {
                        truePositive
                        falsePositive
                        trueNegative
                        falseNegative
                    }
                    cohortBias {
                        truePositive
                        falsePositive
                        trueNegative
                        falseNegative
                    }
                    userLesionBias {
                        lesionName
                        bias {
                            truePositive
                            falsePositive
                            trueNegative
                            falseNegative
                        }
                    }
                }
                progress {
                    completedStudies
                    totalStudies
                }
            }
        }
    }
`);

export const CHECK_COUPON = gql(`
    query CheckCoupon($code: String!) {
        checkCoupon(code: $code) {
            valid
        }
    }
`);
