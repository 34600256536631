import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useGlobalStore } from 'store/zustand/store';
import { css } from '@emotion/react';
import { STUDY_SUBMISSION, STUDY_WITH_ANSWERS_FRAGMENT } from 'graphql/queries';
import { StudyUtils } from 'utils/study-utils';
import { useHistory, useLocation } from 'react-router';
import routes from 'config/routes';
import { useGetStudy } from './use-get-study';
import { useFragment } from '__generated__';
import { AllCasesCompleted } from './all-cases-completed';
import { supabase } from 'index';
import { useStudyTimerStore } from 'store/zustand/study-timer';
import { LoadingOverlay } from '@mantine/core';
import { DumbCasePage } from './dumb-case-page';
import { SubmitButton } from './components/QuestionsDrawer/submit-button';
import QuestionsDrawer from './components/QuestionsDrawer';

export default function CasePage() {
    const location = useLocation();
    const history = useHistory();
    const pathCaseId = location.pathname.split(`${routes.AUTHED.STUDY_PAGE.url}/`)?.[1];
    const studyArgs = pathCaseId ? { studyId: Number(pathCaseId) } : { next: true };
    const study = useGetStudy(studyArgs);
    const studyData = study?.studyData;
    const [setQuestionDrawerOpen, setNavOpen, navOpen] = useGlobalStore((state) => [
        state.setQuestionDrawerOpen,
        state.setNavOpen,
        state.navOpen
    ]);
    const setStartTime = useStudyTimerStore((state) => state.setStartTime);
    const [token, setToken] = useState<string>();

    if (!pathCaseId && studyData?.id) {
        history.replace(`${routes.AUTHED.STUDY_PAGE.url}/${studyData.id}`);
    }

    const { data: submissionData, loading: submissionDataLoading } = useQuery(STUDY_SUBMISSION, {
        skip: !studyData?.id,
        variables: { studyId: studyData?.id ?? -1 },
        fetchPolicy: 'network-only'
    });
    const studyDataForRender =
        useFragment(STUDY_WITH_ANSWERS_FRAGMENT, submissionData?.studySubmission?.study) ?? studyData;

    useEffect(() => {
        setNavOpen(false);

        return () => {
            setNavOpen(true);
        };
    }, [setNavOpen, setQuestionDrawerOpen]);

    const allStudiesCompleted = useMemo(() => {
        if (studyArgs?.next === true) {
            if (study?.called && !study?.loading && !study?.error && !studyData) {
                return true;
            }
        }

        return false;
    }, [study?.called, study?.error, study?.loading, studyArgs?.next, studyData]);

    useEffect(() => {
        supabase.auth.getSession().then((sess) => {
            const tk = sess.data.session?.access_token;
            console.log('got tk', tk);

            setToken(tk);
        });
    }, []);

    useEffect(() => {
        console.log('checking start timer', !!submissionData, submissionDataLoading);

        // if starting new study - i.e. no submission for this study
        if (!submissionData && !submissionDataLoading) {
            console.log('starting timer');
            setStartTime();
        }
    }, [setStartTime, submissionData, submissionDataLoading]);

    if (allStudiesCompleted) {
        return <AllCasesCompleted />;
    }

    const showLoading = study?.loading || !studyDataForRender || !token;

    // apollo/client bug causes loading to remain true on error
    if (showLoading) {
        return <LoadingOverlay visible={showLoading} bg="#0000004b" />;
    }

    const studyInstanceUid = studyDataForRender.dicomStudyUid;
    const ohifUrl = StudyUtils.getOhifUrl({ studyInstanceUid, token });
    console.log('ohifUrl', ohifUrl);

    const sub = submissionData?.studySubmission
        ? {
              ...submissionData.studySubmission,
              study: studyDataForRender
          }
        : null;

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                height: 100vh;
                overflow-y: hidden;
                background-color: black;

                margin-left: ${navOpen ? '10rem' : 0};
                margin-right: 3rem;
            `}
        >
            <QuestionsDrawer currentStudy={studyDataForRender} submission={sub} />
            <div
                css={css`
                    /* height: 5rem; */
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                `}
            >
                <SubmitButton
                    css={css`
                        margin: 0.5rem 1rem;
                    `}
                    onClick={() => setQuestionDrawerOpen(true)}
                >
                    Show Case Questions
                </SubmitButton>
            </div>
            <DumbCasePage studyForRender={studyDataForRender} ohifUrl={ohifUrl} />
        </div>
    );
}
