import { useLazyQuery, useMutation } from '@apollo/client';
import { css } from '@emotion/react';
import {
    Button,
    Divider,
    Group,
    Modal,
    ModalProps,
    Radio,
    Select,
    SimpleGrid,
    TextInput,
    useMantineTheme
} from '@mantine/core';
import { countries } from './countries';
import { MAKE_CHECKOUT_SESSION, UPDATE_USER } from 'graphql/mutations';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { jurisdictions, yearsReporting, ctsReported, organisations, referralSources } from '../../data';
import { CHECK_COUPON } from 'graphql/queries';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import CrossIcon from '@mui/icons-material/HighlightOff';
import { useState } from 'react';
import { mq } from 'config/breakpoints';
import { supabase } from 'index';

interface Props extends Omit<ModalProps, 'onClose'> {}

const pinnedCountries = countries.filter((c) => ['AU', 'US', 'GB'].includes(c.value));
const restCountries = countries.filter((c) => !['AU', 'US', 'GB'].includes(c.value));

const schema = z.object({
    firstName: z.string().min(1, 'Please enter your first name'),
    lastName: z.string().min(1, 'Please enter your last name'),
    country: z.string({ required_error: 'Please select a country' }).min(1, 'Please select a country'),
    accreditationJurisdiction: z.string().min(1, 'Please enter your accreditation jurisdiction'),
    yearsReportingCt: z.string({ required_error: 'Please select an option' }).min(1, 'Please select an option'),
    numCtsReported: z.string({ required_error: 'Please select an option' }).min(1, 'Please select an option'),
    specialty: z.string({ required_error: 'Please select an option' }).min(1, 'Please select an option'),
    organisation: z.string().optional(),
    foundUsBy: z.string().optional(),
    discountCode: z.string().optional()
});

type Schema = z.infer<typeof schema>;

export function NoSubscriptionsModal(props: Props) {
    const [makeCheckoutSession, { loading: loadingCheckuot }] = useMutation(MAKE_CHECKOUT_SESSION);
    const [updateUser, { loading: loadingUpdateUser }] = useMutation(UPDATE_USER);
    const [checkCoupon, { loading: checkCouponLoading }] = useLazyQuery(CHECK_COUPON, { fetchPolicy: 'network-only' });
    const { register, watch, setValue, formState, handleSubmit, getValues } = useForm<Schema>({
        resolver: zodResolver(schema)
    });
    const loading = loadingCheckuot || loadingUpdateUser || formState.isSubmitting;
    const [coupon, setCoupon] = useState<'valid' | 'invalid' | null>(null);
    const theme = useMantineTheme();

    const onContinue = async () => {
        const code = getValues('discountCode');
        let codeValid = false;
        if (!!code && coupon === null) {
            console.log('validating coupon on submit', { code, coupon });
            codeValid = await onCheckCoupon();
        }

        const res = await makeCheckoutSession({
            variables: {
                input: { priceId: import.meta.env.VITE_APP_PRICE_ID, discountCode: code }
            }
        });
        const url = res.data?.makeCheckoutSession?.redirectUrl;

        if (!url) {
            alert('Something went wrong, please contact support.');
            return;
        }

        location.href = url;
    };

    const onSubmit = async (formData: Schema) => {
        const { discountCode, ...rest } = formData;

        const res = await updateUser({
            variables: {
                input: rest
            }
        });

        await onContinue();
    };

    const registerSelect = (fieldName: Parameters<typeof register>[0]) => ({
        value: watch(fieldName),
        onChange: (val: string | null) => setValue(fieldName, val ?? '', { shouldValidate: true }),
        error: formState.errors[fieldName]?.message
    });

    const onCheckCoupon = async (): Promise<boolean> => {
        console.log('checking', getValues('discountCode'));

        const code = getValues('discountCode');
        if (!code) {
            return false;
        }

        try {
            const res = await checkCoupon({ variables: { code } });
            const isValid = res.data?.checkCoupon?.valid;
            setCoupon(isValid ? 'valid' : 'invalid');
            return !!isValid;
        } catch (error) {
            console.log(error);
        }

        return false;
    };

    return (
        <Modal
            {...props}
            title="Complete your account registration"
            onClose={() => {}}
            closeButtonProps={{ style: { display: 'none' } }}
            css={css`
                .mantine-Modal-content {
                    min-width: min(90%, 48rem);
                    width: fit-content;
                }
                .mantine-Modal-title {
                    font-weight: bold;
                }
                .mantine-Modal-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    padding-bottom: 0;
                }
            `}
        >
            <form
                onSubmit={handleSubmit(onSubmit)}
                css={css`
                    width: 100%;
                `}
            >
                <div
                    css={css`
                        min-width: 22rem;
                        display: flex;
                        flex-direction: column;
                        & > * {
                            margin-bottom: 1rem;
                        }
                    `}
                >
                    <SimpleGrid cols={{ base: 1, md: 2 }}>
                        <TextInput
                            {...register('firstName')}
                            label="First Name"
                            placeholder="John"
                            withAsterisk
                            error={formState.errors.firstName?.message}
                            disabled={formState.isSubmitting}
                        />
                        <TextInput
                            {...register('lastName')}
                            label="Last Name"
                            placeholder="Smith"
                            withAsterisk
                            error={formState.errors.lastName?.message}
                            disabled={formState.isSubmitting}
                        />
                        <Select
                            {...registerSelect('country')}
                            label="Country"
                            placeholder="Australia"
                            data={[
                                {
                                    group: '',
                                    items: pinnedCountries
                                },
                                {
                                    group: ' ',
                                    items: restCountries
                                }
                            ]}
                            withAsterisk
                            allowDeselect={false}
                            disabled={formState.isSubmitting}
                        />
                        <div />
                        <Divider
                            css={css`
                                @media (min-width: ${theme.breakpoints.md}) {
                                    grid-column: 1 / 3;
                                }
                                margin: 1rem 0;
                            `}
                        />
                        <Select
                            {...registerSelect('accreditationJurisdiction')}
                            label="Accreditation Jurisdiction"
                            placeholder="Australia / New Zealand"
                            data={jurisdictions}
                            withAsterisk
                            allowDeselect={false}
                            disabled={formState.isSubmitting}
                        />
                        <Select
                            {...registerSelect('yearsReportingCt')}
                            label="Approx years reporting Cardiac CT"
                            placeholder="1-3 years"
                            data={yearsReporting}
                            withAsterisk
                            allowDeselect={false}
                            disabled={formState.isSubmitting}
                        />
                        <Select
                            {...registerSelect('numCtsReported')}
                            label="Number of CT studies reported"
                            placeholder="50"
                            data={ctsReported}
                            withAsterisk
                            allowDeselect={false}
                            disabled={formState.isSubmitting}
                        />
                        <Select
                            {...registerSelect('specialty')}
                            label="Specialty"
                            placeholder="Cardiology"
                            data={[
                                { label: 'Cardiology', value: 'cardiology' },
                                { label: 'Radiology', value: 'radiology' },
                                { label: 'Other', value: 'other' }
                            ]}
                            withAsterisk
                            allowDeselect={false}
                            disabled={formState.isSubmitting}
                        />
                        <Radio.Group label="Do you intend to claim CME points?">
                            <Group gap="lg">
                                <Radio value="yes" label="Yes" disabled={formState.isSubmitting} />
                                <Radio value="no" label="No" disabled={formState.isSubmitting} />
                            </Group>
                        </Radio.Group>
                        <Divider
                            css={css`
                                @media (min-width: ${theme.breakpoints.md}) {
                                    grid-column: 1 / 3;
                                }
                                margin: 1rem 0;
                                margin-bottom: 0;
                            `}
                        />
                        <Select
                            {...registerSelect('organisation')}
                            label="Organistaion"
                            // description="Are you associated with any of our Recognised Organisations?"
                            data={organisations}
                            disabled={formState.isSubmitting}
                        />

                        <Select
                            {...registerSelect('foundUsBy')}
                            label="How did you hear about us?"
                            data={referralSources}
                            disabled={formState.isSubmitting}
                        />
                        <Divider
                            css={css`
                                @media (min-width: ${theme.breakpoints.md}) {
                                    grid-column: 1 / 3;
                                }
                                margin: 1rem 0;
                                margin-bottom: 0;
                            `}
                        />
                        <div
                            css={css`
                                display: flex;
                                gap: 1rem;
                                align-items: flex-end;
                            `}
                        >
                            <TextInput
                                {...register('discountCode')}
                                label="Discount Code"
                                disabled={checkCouponLoading || formState.isSubmitting}
                                css={css`
                                    flex: 1 1 0;
                                `}
                                onChange={() => setCoupon(null)}
                            />
                            {coupon === 'valid' && (
                                <CheckIcon
                                    css={css`
                                        fill: #28bb00;
                                        background: #d4ffd2;
                                        border-radius: 100px;
                                        padding: 4px;
                                        height: 2.2rem;
                                        width: 2.2rem;
                                    `}
                                />
                            )}
                            {coupon === 'invalid' && (
                                <CrossIcon
                                    css={css`
                                        fill: #d50000;
                                        background: #ffd0d0;
                                        border-radius: 100px;
                                        padding: 4px;
                                        height: 2.2rem;
                                        width: 2.2rem;
                                    `}
                                />
                            )}
                            {/* {coupon === null && (
                                <div
                                    css={css`
                                        height: 2rem;
                                        width: 2rem;
                                    `}
                                />
                            )} */}

                            <Button variant="light" onClick={onCheckCoupon} loading={checkCouponLoading}>
                                Apply
                            </Button>
                        </div>
                        {/* <div
                            css={css`
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;
                                margin-top: 2rem;
                            `}
                        ></div> */}
                    </SimpleGrid>
                    <div
                        css={css`
                            display: flex;
                            gap: 0.5rem;
                            justify-content: center;
                            width: 100%;
                            margin-top: 1rem;

                            position: relative;
                        `}
                    >
                        <Button
                            variant="subtle"
                            css={css`
                                font-weight: normal;
                                position: absolute;
                                right: 0;
                            `}
                            onClick={() => supabase.auth.signOut()}
                        >
                            Log Out
                        </Button>
                        <Button loading={loading} disabled={checkCouponLoading || formState.isSubmitting} type="submit">
                            Continue to payment
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
}
